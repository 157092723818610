import SiteButton_ShinyButtonISkinComponent from '../components/SiteButton/viewer/skinComps/BaseButton/ShinyButtonISkin.skin';
import SiteButton_ShinyButtonISkinController from '../components/SiteButton/viewer/SiteButton.controller';


const SiteButton_ShinyButtonISkin = {
  component: SiteButton_ShinyButtonISkinComponent,
  controller: SiteButton_ShinyButtonISkinController
};


export const components = {
  ['SiteButton_ShinyButtonISkin']: SiteButton_ShinyButtonISkin
};

